import React from "react"
import { graphql } from "gatsby"

//components
import Seo from "../components/seo"
import Layout from "../components/layout"
import Sidebar from "../components/sidebar"
import AboutServicesSection from "../components/aboutServicesSection"
import AboutServicesScrollMenu from "../components/aboutServicesSrollMenu"
import PageIntroText from "../components/pageIntroText"

const About = ({ data }) => {
  return (
    <Layout footer>
      {/* sidebar */}
      <Sidebar pageTitle={"ABOUT"}>
        <AboutServicesScrollMenu
          sections={data.sanityAboutPage.aboutSections}
        />
      </Sidebar>
      {/* main content */}
      <div className="md:col-start-5 md:col-span-7">
        <PageIntroText>{data.sanityAboutPage.introText}</PageIntroText>
        {data.sanityAboutPage.aboutSections.map(section => {
          return (
            <AboutServicesSection
              key={section.navigationTitle}
              sectionId={section.navigationTitle}
              sectionImage={section.sectionImage}
              section={section}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export default About

export const Head = () => <Seo title="About" />

export const query = graphql`
  query {
    sanityAboutPage {
      introText
      aboutSections {
        textTitle
        navigationTitle
        _rawSectionText
        sectionImage {
          altText
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`
